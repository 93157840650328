import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { Dialog, DialogCloseCallback } from '../dialog-container/dialog-container.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  viewContainerRef: ViewContainerRef | undefined;
  animationDuration = 230;

  constructor() {}

  registerViewContainer(vcf: ViewContainerRef): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    this.viewContainerRef = vcf;
  }

  open<T, D>(type: Type<unknown>, data?: D): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.viewContainerRef) {
        return;
      }

      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(type);
      const ref = componentRef.instance as Dialog<D, T, string>;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      ref.state = 'open';
      ref.data = data;

      const close = (complete: DialogCloseCallback) => {
        ref.state = 'close';

        setTimeout(() => {
          this.viewContainerRef?.remove();
          complete();
        }, this.animationDuration);
      };

      ref.resolve = (_data: T) => {
        close(() => {
          resolve(_data);
        });
      };

      ref.reject = err => {
        close(() => {
          reject(err);
        });
      };
    });
  }
}
