import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-loading',
  template: `<div [attr.class]="type()"></div>`,
  standalone: true,
  styleUrl: './loading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ opacity: 1 }), animate(230)]),
      transition('* => void', [animate(230, style({ opacity: 0 }))]),
    ]),
  ],
})
export class LoadingComponent {
  type = input<'determinate' | 'indeterminate'>('indeterminate');

  @HostBinding('@flyInOut') get animation(): boolean {
    return true;
  }
}
