import { AbstractStep, isRangeSelectorStep, isStepWithImage } from './models/QuizSteps';
import { StepLink } from './models/QuizStepLinks';
import {
  isBirthdayInputAnswerValue,
  isDurationAnswerValue,
  isExperienceLevelAnswerValue,
  isFlexibilityLevelAnswerValue,
  isGenderAnswerValue,
  isHeightInputAnswerValue,
  isNumberAnswerValue,
  isNumberArrayAnswerValue,
  isWeightInputAnswerValue,
  QuizAnswer,
  QuizAnswerValue,
} from './models/QuizAnswers';
import { QuizConfig, QuizRoutes } from './models/QuizConfig';
import { isNotNullish, isNullish } from '@app/utils';
import {
  AnswerOption,
  DependedQuestionTypes,
  dependenciesMap,
  isRegularQuestion,
  isStepWithQuestion,
  OptionSources,
  QuizQuestionType,
} from '@app/store/quiz/models/QuizQuestion';
import {
  Difficulty,
  Duration,
  durationLabels,
  ExperienceLevel,
  FlexibilityLevel,
  Trainer,
} from '@app/dataset';
import { ProgramParameters, UserProps } from '@app/store/quiz/reducer';

export function getStepById(steps: AbstractStep[], id: string): AbstractStep | undefined {
  return steps.find(step => step.id === id);
}

export function getLinksByFromStepId(links: StepLink[], fromStepId: string): StepLink[] {
  return links.filter(link => link.from === fromStepId);
}

export function getLinksByToStepId(links: StepLink[], toStepId: string): StepLink[] {
  return links.filter(link => link.to === toStepId);
}

export function findNextLink(
  links: StepLink[] | undefined,
  answer: QuizAnswer
): StepLink | undefined {
  const currentStepId = answer.stepId;
  const nextLinks = links?.filter(l => l.from === currentStepId);
  const answerLink = nextLinks?.find(({ answerId }) => answerId === answer.value);
  return answerLink ?? nextLinks?.find(({ answerId }) => isNullish(answerId));
}

export function getNextSteps(config: QuizConfig, currentStepId: string): AbstractStep[] {
  const { steps, links } = config;
  const nextLinks = getLinksByFromStepId(links ?? [], currentStepId);
  const previousLinks = getLinksByToStepId(links ?? [], currentStepId);

  // If there are no next or previous links, return next step in the list (case when steps is before all forks)
  if (nextLinks.length === 0 && previousLinks.length === 0) {
    const currentIndex = steps.findIndex(({ id }) => id === currentStepId);
    return currentIndex >= 0 ? steps.slice(currentIndex + 1, currentIndex + 2) : [];
  }

  return nextLinks.map(link => getStepById(steps, link.to)).filter(isNotNullish);
}

export function getNextStep(config: QuizConfig, answer: QuizAnswer): AbstractStep | undefined {
  const { steps, links } = config;
  const currentStepId = answer.stepId;
  const link = findNextLink(links, answer);

  if (!link) {
    const previousLinks = getLinksByToStepId(links ?? [], currentStepId);
    if (previousLinks.length > 0) {
      // If there are previous links, then this is last step in branch, return undefined
      return undefined;
    }
    // No link found, return next step in the list (case when steps is before all forks)
    const currentIndex = steps.findIndex(({ id }) => id === currentStepId);
    return steps[currentIndex + 1];
  } else {
    // Find step by link
    return getStepById(steps, link.to);
  }
}

export function getStepImages(step: AbstractStep): string[] {
  let images: string[] = [];
  if (isStepWithImage(step) && isNotNullish(step.image)) {
    images.push(step.image);
  }
  if (isStepWithQuestion(step)) {
    images = images.concat(step.question.options.map(({ image }) => image).filter(isNotNullish));
  }
  return images;
}

export function getRoutesList(config: QuizConfig): QuizRoutes {
  const { steps, links } = config;
  if (!links || links.length === 0 || steps.length === 0) {
    // No links, return all steps as a single route
    return [steps.map(step => step.id)];
  }

  const firstRoute = [steps[0].id];
  let nextLinks = getLinksByFromStepId(links, steps[0].id);

  // Find first route until there are multiple next steps
  while (nextLinks.length <= 1) {
    let nextStep;
    if (nextLinks.length === 0) {
      nextStep = steps[firstRoute.length];
    } else {
      nextStep = getStepById(steps, nextLinks[0].to);
    }
    if (!nextStep) {
      return [firstRoute];
    }
    firstRoute.push(nextStep.id);
    nextLinks = getLinksByFromStepId(links, nextStep.id);
  }

  // Find all possible routes using nextLinks
  let routes: QuizRoutes = [firstRoute];
  while (nextLinks.length > 0) {
    const newNextLinks: StepLink[] = [];
    // Iterate over all routes and add next steps and new links
    routes = routes.reduce((newRoutes, route) => {
      const lastStepId = route[route.length - 1];
      const linksForStep = getLinksByFromStepId(links, lastStepId);
      if (linksForStep.length === 0) {
        // No next steps, return current route
        newRoutes.push(route);
        return newRoutes;
      }
      // For all links, create own routes with next steps
      linksForStep.forEach(link => {
        const nextStep = getStepById(steps, link.to);
        if (!nextStep) {
          newRoutes.push(route);
          return;
        } else {
          newRoutes.push([...route, nextStep.id]);
          const nextStepLinks = getLinksByFromStepId(links, nextStep.id);
          if (nextStepLinks.length > 0) {
            newNextLinks.push(...nextStepLinks);
          }
        }
      });
      return newRoutes;
    }, [] as QuizRoutes);
    nextLinks = newNextLinks;
  }

  return routes;
}

// Update user properties based on the answer
export function updateUserProps(step: AbstractStep, value: QuizAnswerValue): UserProps {
  let userProps: UserProps = {};
  if (isNotNullish(value) && isHeightInputAnswerValue(value)) {
    const { centimeters, feet, inches } = value;
    userProps = {
      ...userProps,
      growth: centimeters,
      growthFt: feet,
      growthInch: inches,
    };
  }
  if (isNotNullish(value) && isWeightInputAnswerValue(value)) {
    const { kilograms, pounds } = value;
    userProps = {
      ...userProps,
      weight: kilograms,
      weightLb: pounds,
    };
  }
  if (isNotNullish(value) && isBirthdayInputAnswerValue(value)) {
    const { day, month, year } = value;
    userProps = {
      ...userProps,
      birthday: `${year}-${month}-${day}`,
    };
  }
  const questionType = getQuestionType(step);
  if (isGenderAnswerValue(value) && questionType === QuizQuestionType.gender) {
    userProps = { ...userProps, gender: value };
  }
  return userProps;
}

export function updateProgramParams(
  step: AbstractStep,
  value: QuizAnswerValue,
  label?: string
): ProgramParameters {
  let programParams: ProgramParameters = {};
  const questionType = getQuestionType(step);
  switch (questionType) {
    case QuizQuestionType.challenge:
      if (isNumberAnswerValue(value))
        programParams = { ...programParams, challenge_id: value, name: label };
      if (isNumberArrayAnswerValue(value)) {
        const [challenge_id, name] = calcSplitProgram(value);
        programParams = { ...programParams, challenge_id, name };
      }
      break;
    case QuizQuestionType.experience:
      if (isExperienceLevelAnswerValue(value))
        programParams = { ...programParams, experienceLevel: value };
      break;
    case QuizQuestionType.flexibility:
      if (isFlexibilityLevelAnswerValue(value))
        programParams = { ...programParams, flexibilityLevel: value };
      break;
    case QuizQuestionType.duration:
      if (isDurationAnswerValue(value)) {
        programParams = { ...programParams, durations: value };
      }
      break;
    case QuizQuestionType.trainer:
      if (isNumberArrayAnswerValue(value)) {
        programParams = { ...programParams, trainers: value };
      }
      break;
    case QuizQuestionType.days:
      if (isNumberAnswerValue(value)) programParams = { ...programParams, days: value };
      break;
    case QuizQuestionType.intensity:
      if (isNumberAnswerValue(value)) programParams = { ...programParams, intensity: value };
      break;
    default:
      break;
  }
  return programParams;
}

export function getQuestionType(step: AbstractStep): QuizQuestionType | undefined {
  if (isRangeSelectorStep(step)) {
    return step.questionType;
  }
  if (isStepWithQuestion(step)) {
    return !isRegularQuestion(step.question) ? step.question.questionType : undefined;
  }
  return undefined;
}

// Difficulty level calculation
function isBeginner(exp: ExperienceLevel, flex: FlexibilityLevel): boolean {
  return (
    (flex === FlexibilityLevel.NOT_FLEXIBLE && exp <= ExperienceLevel.NOT_REGULARLY) ||
    (flex === FlexibilityLevel.SOMEWHAT_FLEXIBLE && exp === ExperienceLevel.NEVER_STRETCHED)
  );
}
function isIntermediate(exp: ExperienceLevel, flex: FlexibilityLevel): boolean {
  return (
    (flex === FlexibilityLevel.NOT_FLEXIBLE && exp <= ExperienceLevel.REGULARLY_OVER_YEAR) ||
    (flex === FlexibilityLevel.SOMEWHAT_FLEXIBLE && exp <= ExperienceLevel.REGULARLY_6_MONTH) ||
    (flex === FlexibilityLevel.PRETTY_FLEXIBLE && exp === ExperienceLevel.NEVER_STRETCHED)
  );
}
function isFluent(exp: ExperienceLevel, flex: FlexibilityLevel): boolean {
  return (
    (flex === FlexibilityLevel.SOMEWHAT_FLEXIBLE && exp <= ExperienceLevel.REGULARLY_OVER_YEAR) ||
    (flex === FlexibilityLevel.PRETTY_FLEXIBLE && exp <= ExperienceLevel.NOT_REGULARLY)
  );
}
function isAdvanced(exp: ExperienceLevel, flex: FlexibilityLevel): boolean {
  return flex === FlexibilityLevel.PRETTY_FLEXIBLE && exp <= ExperienceLevel.REGULARLY_OVER_YEAR;
}
export function calcComplexity(exp: ExperienceLevel, flex: FlexibilityLevel): Difficulty {
  let level = Difficulty.BEGINNER;

  if (isBeginner(exp, flex)) {
    level = Difficulty.BEGINNER;
  } else if (isIntermediate(exp, flex)) {
    level = Difficulty.INTERMEDIATE;
  } else if (isFluent(exp, flex)) {
    level = Difficulty.FLUENT;
  } else if (isAdvanced(exp, flex)) {
    level = Difficulty.ADVANCED;
  }
  return level;
}

// Split program calculation
export function calcSplitProgram(challengeIds: number[]): [number, string] {
  const selectedPrograms = new Set(challengeIds);
  let challengeId = 8;
  let name = 'Front Splits';

  if (selectedPrograms.has(8) && selectedPrograms.has(4) && selectedPrograms.has(3)) {
    challengeId = 12;
    name = 'Everything Splits';
  } else if (selectedPrograms.has(8) && selectedPrograms.has(4)) {
    challengeId = 33;
    name = 'Front and Middle Splits';
  } else if (selectedPrograms.has(8) && selectedPrograms.has(3)) {
    challengeId = 3;
    name = 'Front and Standing Splits';
  } else if (selectedPrograms.has(4) && selectedPrograms.has(3)) {
    challengeId = 43;
    name = 'Middle and Standing Splits';
  } else if (selectedPrograms.size === 1) {
    challengeId = challengeIds[0];
    name =
      [
        {
          label: 'Front Splits',
          id: 8,
        },
        {
          label: 'Middle Split',
          id: 4,
        },
        {
          label: 'Standing Splits',
          id: 3,
        },
      ].find(({ id }) => id === challengeId)?.label ?? '';
  }
  return [challengeId, name];
}

// Build depended question options
function buildDurationsOptions(durations: Duration[][]): AnswerOption<number>[] {
  return durations
    .reduce((acc, durations) => {
      durations.forEach(duration => {
        if (!acc.some(({ id }) => id === duration))
          acc.push({ id: duration, label: durationLabels[duration] });
      });
      return acc;
    }, [] as AnswerOption<number>[])
    .sort((a, b) => a.id - b.id);
}
function buildTrainersOptions(trainers: Trainer[][]): AnswerOption<number>[] {
  const pattern: Record<number, number> = {
    1: 1,
    3: 2,
    2: 3,
    4: 4,
  };
  return trainers
    .reduce((acc, trainers) => {
      trainers.forEach(trainer => {
        if (!acc.some(({ id }) => id === trainer.id))
          acc.push({ id: trainer.id, label: trainer.name, image: trainer.avatar });
      });
      return acc;
    }, [] as AnswerOption<number>[])
    .sort((a, b) => {
      return (pattern[a.id] ?? a.id) - (pattern[b.id] ?? b.id);
    });
}
export function buildDependedOptions(
  sources: OptionSources,
  questionType: DependedQuestionTypes
): AnswerOption<number>[] {
  const key = dependenciesMap[questionType];

  if (key === 'trainers') {
    return buildTrainersOptions(sources[key]);
  }

  if (key === 'durations') {
    return buildDurationsOptions(sources[key]);
  }

  return [];
}
