type Nullish = null | undefined;

export function isNullish<T>(value: T | Nullish): value is Nullish {
  return value === null || value === undefined;
}

export function isNotNullish<T>(value: T | Nullish): value is T {
  return !isNullish(value);
}

export function isNotNullishValues<T extends object>(
  obj: T
): obj is { [K in keyof T]: Exclude<T[K], Nullish> } {
  for (const key in obj) {
    if (isNullish(obj[key])) {
      return false;
    }
  }
  return true;
}

export function isNotNullishAll<T>(arr: (T | null | undefined)[]): arr is T[] {
  return arr.every(isNotNullish);
}
