import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { QuizAnswer } from './models/QuizAnswers';
import { QuizConfig, QuizRoutes } from './models/QuizConfig';
import { Review } from '@app/services/dictionary/dictionary.service';
import { AbstractStep } from '@app/store/quiz/models/QuizSteps';
import { ProgramParameters, UserProps } from '@app/store/quiz/reducer';
import { OptionSources } from '@app/store/quiz/models/QuizQuestion';

export const QuizActions = createActionGroup({
  source: 'Quiz Page',
  events: {
    'Set User Props': props<{ userProps: UserProps }>(),
    'Set Program Params': props<{ programParams: ProgramParameters }>(),

    'Load Quiz': props<{ name: string }>(),
    'Load Quiz Success': props<{
      config: QuizConfig;
      reviews: Review[];
      routes: QuizRoutes;
      name: string;
    }>(),
    'Load Quiz Failure': emptyProps(),

    'Next Step': props<{ answer: QuizAnswer }>(),
    'Next Step Loaded': props<{
      nextStep?: AbstractStep;
    }>(),

    'Previous Step': emptyProps(),

    'Load Options Source': props<{ source: keyof OptionSources; params: ProgramParameters }>(),
    'Load Options Source Success': props<{
      source: keyof OptionSources;
      value: OptionSources[keyof OptionSources];
    }>(),
    'Load Options Source Failure': props<{ source: keyof OptionSources }>(),
  },
});
