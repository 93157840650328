import { delay, distinctUntilChanged, filter, Observable, of, switchMap, take } from 'rxjs';

export function waitForTrue(
  source: Observable<boolean>,
  delayTime = 0,
  takeN = 1
): Observable<true> {
  return of(true).pipe(
    delay(delayTime),
    switchMap(() => source.pipe(filter(Boolean), take(takeN)))
  );
}

export function distinctUntilChangedDeep<T extends object>(): (
  source: Observable<T>
) => Observable<T> {
  return source =>
    source.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
}
