import { QuizConfig, QuizRoutes } from './models/QuizConfig';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { QuizActions } from './actions';
import { QuizAnswer } from './models/QuizAnswers';
import {
  buildDependedOptions,
  getNextSteps,
  getQuestionType,
  getStepById,
  getStepImages,
  updateProgramParams,
  updateUserProps,
} from './helpers';
import { Review } from '@app/services/dictionary/dictionary.service';
import { Difficulty, Duration, ExperienceLevel, FlexibilityLevel } from '@app/dataset';
import {
  dependenciesMap,
  isDependedQuestionType,
  isStepWithQuestion,
  OptionSources,
} from '@app/store/quiz/models/QuizQuestion';
import { isNotNullish, isNullish } from '@app/utils';

export type UserProps = {
  stage?: string;
  birthday?: string;
  weight?: string;
  growth?: string;
  weightLb?: string;
  growthFt?: string;
  growthInch?: string;
  challengeId?: string;
  onboardId?: string;
  loading?: boolean;
  gender?: string;
};

export type ProgramParameters = {
  name?: string;
  challenge_id?: number;
  experienceLevel?: ExperienceLevel;
  flexibilityLevel?: FlexibilityLevel;
  complexity?: Difficulty;
  durations?: Duration[];
  days?: number;
  intensity?: number;
  trainers?: number[];
};

export type OptionSourcesLoadingState = {
  [key in keyof OptionSources]: boolean;
};

interface State {
  config: QuizConfig;
  name: string;
  userProps: UserProps;
  programParams: ProgramParameters;
  optionSources: OptionSources;
  optionSourcesLoading: OptionSourcesLoadingState;
  currentStepId: string;
  history: string[];
  routes: QuizRoutes;
  answers: Record<string, QuizAnswer>;
  reviews: Review[];
  isFinished: boolean;
  isLoading: boolean;
  isStepLoading: boolean;
  configNotFound: boolean;
}

const initialState: State = {
  config: {
    steps: [],
    links: [],
  },
  name: '',
  userProps: {},
  programParams: {},
  optionSources: {
    durations: [],
    trainers: [],
  },
  optionSourcesLoading: {
    durations: false,
    trainers: false,
  },
  currentStepId: '',
  history: [],
  routes: [],
  answers: {},
  reviews: [],
  isFinished: false,
  isLoading: true,
  isStepLoading: false,
  configNotFound: false,
};

export const QuizFeature = createFeature({
  name: 'quiz',
  reducer: createReducer(
    initialState,

    on(QuizActions.setUserProps, (state, { userProps }) => {
      let programParams = state.programParams;
      if (userProps.challengeId) {
        programParams = { ...programParams, challenge_id: Number(userProps.challengeId) };
      }
      return {
        ...state,
        userProps: { ...state.userProps, ...userProps },
        programParams,
      };
    }),

    on(QuizActions.setProgramParams, (state, { programParams }) => {
      return {
        ...state,
        programParams: { ...state.programParams, ...programParams },
      };
    }),

    // Initialize quiz
    on(QuizActions.loadQuiz, state => {
      return {
        ...state,
        isLoading: true,
      };
    }),

    on(QuizActions.loadQuizSuccess, (state, { config, reviews, routes, name }) => {
      const steps = config.steps;
      return {
        ...state,
        config,
        name,
        reviews,
        currentStepId: steps[0].id ?? '',
        routes,
        isLoading: false,
      };
    }),

    on(QuizActions.loadQuizFailure, state => {
      return {
        ...state,
        isLoading: false,
        configNotFound: true,
      };
    }),

    // Go to next step
    on(QuizActions.nextStep, (state, { answer }) => {
      const { value, stepId, label } = answer;
      const step = getStepById(state.config.steps, stepId);
      let { programParams, userProps, answers } = state;

      // Update program parameters based on the answer
      if (step && isNotNullish(value)) {
        userProps = { ...userProps, ...updateUserProps(step, value) };
        programParams = { ...programParams, ...updateProgramParams(step, value, label) };
      }

      answers = { ...answers, [stepId]: answer };
      return {
        ...state,
        programParams,
        userProps,
        answers,
        isStepLoading: true,
      };
    }),

    on(QuizActions.nextStepLoaded, (state, { nextStep }) => {
      const { currentStepId, history } = state;
      const newHistory = [...history, currentStepId];
      if (!nextStep) {
        return {
          ...state,
          history: newHistory,
          isFinished: true,
          isStepLoading: false,
        };
      }
      return {
        ...state,
        currentStepId: nextStep.id,
        history: newHistory,
        isStepLoading: false,
      };
    }),

    // Go to previous step
    on(QuizActions.previousStep, state => {
      const [...newHistory] = state.history;
      const currentStepId = newHistory.pop();
      if (!currentStepId) {
        return state;
      }
      return {
        ...state,
        currentStepId,
        history: newHistory,
      };
    }),

    // Load options sources
    on(QuizActions.loadOptionsSource, (state, { source }) => {
      const optionSourcesLoading = { ...state.optionSourcesLoading, [source]: true };
      return {
        ...state,
        optionSourcesLoading,
      };
    }),

    on(QuizActions.loadOptionsSourceSuccess, (state, { source, value }) => {
      const optionSources = { ...state.optionSources, [source]: value };
      const optionSourcesLoading = { ...state.optionSourcesLoading, [source]: false };
      return {
        ...state,
        optionSources,
        optionSourcesLoading,
      };
    }),

    on(QuizActions.loadOptionsSourceFailure, (state, { source }) => {
      const optionSourcesLoading = { ...state.optionSourcesLoading, [source]: false };
      return {
        ...state,
        optionSourcesLoading,
      };
    })
  ),

  // Selectors
  extraSelectors: ({
    selectConfig,
    selectCurrentStepId,
    selectHistory,
    selectRoutes,
    selectOptionSources,
  }) => {
    const selectSteps = createSelector(selectConfig, config => config.steps);

    const selectCurrentStep = createSelector(
      selectSteps,
      selectCurrentStepId,
      (steps, currentStepId) => getStepById(steps, currentStepId)
    );

    const selectNextStepsImages = createSelector(
      selectConfig,
      selectCurrentStepId,
      (config, currentStepId) => {
        const nextSteps = getNextSteps(config, currentStepId);
        return nextSteps.flatMap(getStepImages);
      }
    );

    const selectCanBack = createSelector(selectHistory, history => history.length > 0);

    const selectProgress = createSelector(selectRoutes, selectCurrentStepId, (routes, stepId) => {
      const availableRoutes = routes.filter(route => route.some(id => stepId === id));
      const maxRoute = availableRoutes.reduce(
        (max, route) => (route.length > max.length ? route : max),
        []
      );
      const index = maxRoute.indexOf(stepId) + 1;
      return index / maxRoute.length;
    });

    const selectOptionSource = createSelector(
      selectCurrentStep,
      selectOptionSources,
      (step, optionSources) => {
        if (isNullish(step) || !isStepWithQuestion(step)) {
          return [];
        }

        const questionType = getQuestionType(step);
        if (isNotNullish(questionType) && isDependedQuestionType(questionType)) {
          return optionSources[dependenciesMap[questionType]];
        }

        return [];
      }
    );

    const selectQuestionOptions = createSelector(
      selectCurrentStep,
      selectOptionSources,
      (step, optionSources) => {
        if (isNullish(step) || !isStepWithQuestion(step)) {
          return [];
        }

        const questionType = getQuestionType(step);
        if (isNotNullish(questionType) && isDependedQuestionType(questionType)) {
          return buildDependedOptions(optionSources, questionType);
        }

        return step.question.options;
      }
    );

    const selectQuizParams = createSelector(selectConfig, config => {
      return config.params;
    });

    return {
      selectSteps,
      selectCurrentStep,
      selectCanBack,
      selectProgress,
      selectNextStepsImages,
      selectOptionSource,
      selectQuestionOptions,
      selectQuizParams,
    };
  },
});
